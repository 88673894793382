import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorPawel as author } from 'data/authors';
import img from 'img/blog/clickmap.png';
import img1 from 'img/blog/clickmaps1.png';
import img2 from 'img/blog/clickmaps2.png';
import Banner from 'components/usability-testing/banner';

const Content = () => {
  return (
    <Post>
      <p>
        This feature has been requested a lot, and it’s finally here! We’ve just released{' '}
        <strong>click maps</strong>.
      </p>
      <p>
        Click maps show you where the users clicked on your page. They also display{' '}
        <a href="/blog/error-rage-clicks/" target="_blank" rel="noopener noreferrer">
          rage clicks and error clicks
        </a>
        .{' '}
      </p>
      <p>
        Our click maps are constantly developing, LiveSession allows users to access the BETA
        version in the app. To gain access to this new feature, go on any session recording, and
        click the “Clickmap BETA” to request the use of it.
      </p>
      <p>LiveSession click maps are:</p>
      <ul>
        <li>Dynamic – You can use any filter you like to segmentize the results</li>
        <li>
          Fully retroactive – Click maps can be created from data you’ve collected in the past
        </li>
        <li>Comprehensive – They use all available data to create a visualization</li>
      </ul>
      <p>See what they look like in action:</p>
      <p>
        <img src={img1} alt="Clickmaps" title="Clickmaps in action!" />
      </p>
      <Banner title="Try clickmaps now" />
      <p>
        In the near future, you will be able to display click maps as heatmaps. Sign up for our
        newsletter to receive updates! Here’s a small preview of what you can expect:
      </p>
      <p>
        <img src={img2} alt="Clickmaps as heatmaps" title="Click maps as heatmaps" />
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Clickmaps',
  url: '/blog/clickmaps/',
  description: `Introducing Clickmaps with engagement Heatmaps - an efficient way to learn what your website or app users are doing`,
  author,
  img,
  imgSocial: img,
  date: '2020-10-09',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
